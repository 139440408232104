import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import * as styles from "../components/index.module.css"

const formations = [
    {
        name: 'Mastère en Architecture Web et Big Data',
        date: '2020-2022',
        description : 'Ecole IPSSI',
        type: 'Paris',
        link: 'https://ecole-ipssi.com/',
        longDescription: 'Je me spécialise en IA et Big Data en préparant un diplôme BAC+5 (Titre RNCP de niveau 7). Cette spécialisation me permet entre autres d\'acquérir les compétences nécéssaires pour mener à bien des projets complexes et variés.',
    },
    {
        name: 'Licence Développement Web',
        date: '2019-2020',
        description : 'CNAM',
        type: 'Hauts de France',
        link: 'https://www.cnam.fr/',
        longDescription: 'En 2019, je poursuis ma formation en alternance au Conservatoire National des Arts et Métiers en intégrant une Licence Informatique Générale mention Developement Web. J\'y apprend notamment les bases de la cybersécurité, du management de projets, des mathématiques appliquées, ainsi que l\'approfondissement des technos web avec de nombreux frameworks.',
    },
    {
        name: 'BTS SIO option SLAM',
        date: '2017-2019',
        description : 'Saint Vincent',
        type: 'Senlis',
        link: 'https://enseignement-superieur.lycee-stvincent.fr/',
        longDescription: 'Après l\'obtention du BAC SIG (Système d\'Information de Gestion) avec mention, je décide de continuer mes études en BTS SIO opt. Solutions Logicielles et Applications Métiers. Ce diplôme m\'a permis de découvrir plusieurs langages tels que : HTML, CSS, C#, Javascript, PHP et MySQL',
    },
  ]  

const FormationsPage = () => (
  <Layout>
    <Seo title="Formations - Guillaume Dominici" />
    <h1 className={styles.textTitle}>
        Formations
    </h1>
    <p className={styles.description}>
        Fort de 5 années d'apprentissage dans le domaine du développement web et Big Data. 
        <br></br>
        Je m'appuis aujourd'hui sur des formations connues et reconnues. 
    </p>
    <div>
        <div>
            {formations.map((formation, i) => (
                <div 
                    style={{
                        padding: '1rem 1rem 1px 1rem',
                        borderLeft: '2.5px solid',
                        borderColor: `rgba(${r()}, ${r()}, ${r()}, 0.5)`,
                        // borderRadius: '10px',
                        margin: '0 0 1rem 0'
                    }}    
                    >
                    <div className={styles.formationsPart}>
                        <h3 style={{
                            float: 'left'
                        }}>{formation.name}</h3>
                        <span style={{
                            float: 'right',
                            color: 'grey',
                            fontSize: '14px',
                            marginTop: '7px'
                        }}>{formation.date}</span>
                    </div>
                    <p style={{
                        display: 'block',
                        fontSize: '14px',
                        color: 'grey',
                        margin: 0,
                    }}>
                    <a target="_blank" href={formation.link}>{formation.description}</a> - {formation.type}
                    </p>
                    <p style={{
                        fontSize: '16px',
                        marginTop: '0.5rem'
                    }}>
                        {formation.longDescription}
                    </p>
                </div>
            ))}
        </div>
    </div>
  </Layout>
)

export default FormationsPage

var r = () => Math.random() * 256 >> 0;